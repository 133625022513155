<template>
    <div class="individual-vendor-details">
      <div v-if="loading">loading</div>
      <template v-else>
        <eden-page-header :title="'Vendors'" :subtitle="customerName">
        </eden-page-header>
        <div>
            <OrderTypes :data="vendorSummary" />
        </div>
      </template>
    </div>
  </template>
  
  <script>
//   import VendorDetailsSummary from "@/components/Vendors/details/vendor-details-summary.vue";
//   import vendorBusinessDetails from "./vendor-business-details.vue";
  import details from "@/requests/vendors/details.js";
  import OrderTypes from "../../components/Vendors/details/OrderTypes.vue";
  
  export default {
    components: {
      OrderTypes,
    },
    data() {
      return {
        loading: false,
        vendorDetails: {},
        // vendorWorkingHoursDetails: {},
        // tab: "other",
        // acceptOrReject: null,
      };
    },
    created() {
      this.getVendor()
    //   this.getWorkingHours()
    },
    computed: {
      customerName() {
        return `${this.vendorSummary.business_name}`;
      },
      vendorSummary() {
      if (!this.vendorDetails?.id) return {};
      return {
        image_url: this.vendorDetails?.business_information.image_url,
        id: this.vendorDetails.id,
        business_name: this.vendorDetails?.business_information.business_name,
        business_type: this.vendorDetails?.business_information.business_type,
        business_status: this.vendorDetails?.business_information.status,
        is_store_open: this.vendorDetails?.business_information.is_store_open,
        delivery_window: this.vendorDetails?.business_information.operation_mode,
        vendor_account_creator: {
          ...this.vendorDetails.vendor_account_creator,
        },
      };
    },
    },
    methods: {
      getVendor() {
        this.loading = true;
        details
          .list(this.$route.params.id)
          .then((response) => {
            if (response.data.status) {
              console.log(response.data);
              this.vendorDetails = response.data.data;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      },
    //   getWorkingHours() {
    //     this.loading = true;
    //     details
    //       .workingHours(this.$route.params.id)
    //       .then((response) => {
    //         if (response.data.status) {
    //           this.loading = false;
    //           console.log(response.data, "passs");
    //           this.vendorWorkingHoursDetails = response.data.data;
    //           // this.loading = false;
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.loading = false;
    //       });
  
    //   },
    //   updateRouteQuery(tab) {
    //     const { t } = this.$route.query;
  
    //     if (t === this.tab) {
    //       return false;
    //     }
    //     this.$router.push({
    //       name: "vendors.details",
    //       params: { id: this.$route.params.id },
    //       query: { t: tab.name },
    //     });
    //   },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  $-summary-width: 320px;
  
  .individual-vendor-details {
    .body {
      margin-top: 45px;
  
      .detail {
        margin-left: 30px;
        width: calc(100% - #{$-summary-width});
      }
    }
  
    @media (max-width: 992px) {
      .body {
        flex-wrap: wrap;
  
        .detail {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
  </style>
  